import React from 'react'
import { FieldErrors, FieldName } from 'react-hook-form/dist/types'
import { FormattedMessage } from 'react-intl'
import styles from './index.module.scss'
import Error from '../../../common/components/error/Error'
import FormError from '../../../common/components/FormError/FormError'
import DropDown, { Props } from '../DropDown/DropDown'
import { t } from '../../../common/i18n'

interface WithFormError<T> {
  name: FieldName<T>
  errors?: FieldErrors<T>
}
interface WithStringError {
  name: string
  errors: string
}
type withErrors<T> = WithFormError<T> | WithStringError

type InputProps<T> = {
  disabled?: boolean
  defaultSelected?: string | number | null
  loading?: boolean
} & withErrors<T> &
  Props

function InputDropDown<T>({
  labelId,
  labelAbove,
  errors,
  name,
  disabled,
  defaultSelected,
  loading,
  searchable = false,
  ...rest
}: InputProps<T>) {
  rest.placeholder = loading ? t('revisiones.cargando') : rest.placeholder
  return (
    <div className={styles.input_wrapper}>
      {!!labelId && !!labelId.trim() && (
        <label className={labelAbove ? styles.label_above : styles.label}>
          <FormattedMessage id={labelId} />
        </label>
      )}
      <div className={styles.input}>
        <DropDown
          defaultSelected={defaultSelected}
          name={name}
          disabled={disabled}
          searchable={searchable}
          labelAbove={labelAbove}
          {...rest}
        />
        {typeof errors === 'string' ? (
          errors && <Error message={errors}></Error>
        ) : (
          <FormError errors={errors} field={name as FieldName<T>} />
        )}
      </div>
    </div>
  )
}

export default InputDropDown
